var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'total-project-hour-dashboard dashtextShadow':!_vm.loader}},[(_vm.loader)?_c('progress-shimmer',{staticClass:"generalshimmer"}):_c('div',[_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('div',[_c('h3',{staticClass:"mb-0 total-hours-time"},[_vm._v(" "+_vm._s(_vm._f("hoursInTimeFormat")((_vm.totalProjectMinutes / 60)))+" ")]),_c('p',{staticClass:"time-title-dashboard"},[_vm._v(" Total Time Spent ")])]),_c('b-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"icon-day"},[_vm._v(" "+_vm._s(_vm.taskDateRange))]),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"CalendarIcon"}})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){_vm.taskDateRange = 'Today'}}},[_vm._v("Today ")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.taskDateRange = 'This Week'}}},[_vm._v("This week")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.taskDateRange = 'This Month'}}},[_vm._v("This Month")])],1)],1),_c('div',{staticClass:"hr-line-dashboard"}),_c('div',{staticClass:"all-project-progress"},[_vm._l((_vm.userProjectLogList),function(progressData,index){return _c('div',{key:index},[_c('div',{staticClass:"total-project-hour-user"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"user-projects-name",style:({ color: '#' + progressData.color })},[_vm._v(" "+_vm._s(progressData.name)+" ")]),_c('span',{staticClass:"user-total-hour"},[_vm._v(" "+_vm._s(_vm._f("hoursInTimeFormat")((progressData.total_minutes / 60)))+" ")])]),_c('div',{staticClass:"new-progress-bar"},[_c('div',{staticClass:"project-progress-custom",style:({ backgroundColor: '#' + progressData.color })}),_c('div',{staticClass:"progress-done",style:({
                width:
                  (progressData.total_minutes / _vm.totalProjectMinutes) *
                  100 +
                  '%',
                backgroundColor: '#' + progressData.color,
              })})])])])}),(
          _vm.userProjectLogList &&
            _vm.userProjectLogList.length == 0 &&
            _vm.loader == false
        )?_c('div',[_c('div',{staticClass:"total-project-hour-user"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"no-data-img no-data-dashboard",attrs:{"src":require("../../assets/images/nodatafound/nodata.svg"),"alt":"no-data"}})])])]):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }