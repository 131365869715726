<template>
  <div class="">
    <progress-shimmer
      v-if="loader_autodayout"
      class="mt-1"
    />
    <div
      v-else
      class="total-project-hour-dashboard hrinoutinfo autoDayout newMxHeight mt-1 mb-2"
    >
      <div class="d-flex align-items-center justify-content-between hrFlatpicker">
        <div>
          <h3 class="mb-0 total-hours-time">
            <span>Recent Projects</span>
          </h3>
        </div>
        <div class="hrDaytime">
          <OptionDropdown
            v-model="perPage"
            :options="['5','10']"
          />
        </div>
      </div>
      <div class="all-project-progress hr-dashboard">
        <ul
          v-if="projectsList && projectsList.length"
          class="p-0 m-0"
        >
          <li>
            <table>
              <tbody>
                <tr
                  v-for="(project,index) in projectsList"
                  :key="index"
                  class="cursor-pointer"
                  @click="projectSummary(project)"
                >
                  <td class="empName">
                    <div class="d-flex align-items-center">
                      <b-avatar
                        :text="project.name | avatarText"
                        class="mr-50"
                        :style="{ 'background-color': '#' + project.color }"
                      />
                      <p
                        v-b-tooltip.hover.top="project.name"
                        class="tbltxtWrap d-inline-block"
                        :style="{ color: '#' + project.color }"
                      >
                        {{ project.name }}
                      </p>
                    </div>
                  </td>
                  <td class="emplstcol">
                    Created by  {{ project.created_by.full_name }}
                    <span class="d-block">on {{ project.created_at | DDMMYYYYDDDD }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>

        </ul>
      </div>

      <div
        v-if="projectsList && !projectsList.length"
        class="d-flex align-items-center"
      >
        <img
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img no-data-dashboard"
        >
      </div>
    </div>
  </div>
</template>

<script>
import ProgressShimmer from '../../views/shimmer/ProgressShimmer.vue'
import OptionDropdown from '../optiondropdown/OptionDropdown.vue'

export default {
  name: 'AutoDayOut',
  components: {
    ProgressShimmer,
    OptionDropdown,
  },

  data() {
    return {
      projectsList: [],
      loader_autodayout: false,
      perPage: 5,
    }
  },

  watch: {
    perPage() {
      this.recentProjectList()
    },

  },

  mounted() {
    this.recentProjectList()
  },
  methods: {

    /**
   * Get the login and filter users activty logs
   * @return logs
   */
    async recentProjectList() {
      this.loader_autodayout = true

      const input = {
        page: 1,
        per_page: this.perPage,
        sort_field: 'created_at',
        sort_order: 'desc',
      }

      const response = await this.getHTTPPostResponse(
        'project/recent-projects',
        input,
        false,
      )
      if (response && response.data) {
        this.projectsList = response.data.projects
        this.loader_autodayout = false
      }
    },
    /* view project */
    projectSummary(project) {
      this.$router.push({
        name: 'projectTask',
        params: {
          id: 'summary',
          id2: project.name,
          id3: project.id,

          page_name: 'dashboard',
        },
      })
    },

  },
}
</script>
