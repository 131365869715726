<template>
  <div>
    <b-col class="total-hours-dashboard">
      <div class="d-flex align-items-start justify-content-between">
        <div>
          <h3 class="mb-0 total-hours-time">
            <short-text></short-text><short-text></short-text>
          </h3>
          <p class="time-title-dashboard"><short-text></short-text></p>
        </div>

        <p><short-text></short-text><short-text></short-text></p>
      </div>
      <b-row class="total-hour-log">
        <b-col cols="9" class="pl-0 total-hour-log-content">
          <div class="d-flex align-items-start">
            <circle-shimmer class="mr-1"></circle-shimmer>
            <div>
              <p class="task-des-dashbord">
                <text-shimmer></text-shimmer>
              </p>
              <span class="projectname-total-hour">
                <text-shimmer class="mt-1"></text-shimmer>
              </span>
            </div>
          </div>
        </b-col>
        <b-col cols="3" class="total-hour-log-content text-right p-0"
          ><span class="day-hour-dashboard"><short-text></short-text></span
        ></b-col>
        <b-col cols="9" class="pl-0 total-hour-log-content">
          <div class="d-flex align-items-start">
            <circle-shimmer class="mr-1"></circle-shimmer>
            <div>
              <p class="task-des-dashbord">
                <text-shimmer></text-shimmer>
              </p>
              <span class="projectname-total-hour">
                <text-shimmer class="mt-1"></text-shimmer>
              </span>
            </div>
          </div>
        </b-col>
        <b-col cols="3" class="total-hour-log-content text-right p-0"
          ><span class="day-hour-dashboard"><short-text></short-text></span
        ></b-col>
        <b-col cols="9" class="pl-0 total-hour-log-content">
          <div class="d-flex align-items-start">
            <circle-shimmer class="mr-1"></circle-shimmer>
            <div>
              <p class="task-des-dashbord">
                <text-shimmer></text-shimmer>
              </p>
              <span class="projectname-total-hour">
                <text-shimmer class="mt-1"></text-shimmer>
              </span>
            </div>
          </div>
        </b-col>
        <b-col cols="3" class="total-hour-log-content text-right p-0"
          ><span class="day-hour-dashboard"><short-text></short-text></span
        ></b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import ShortText from "./shortText.vue";
import TextShimmer from "./TextShimmer.vue";
import CircleShimmer from "./CircleShimmer.vue";
export default {
  name: "TotalHourShimmer",
  components: {
    ShortText,
    CircleShimmer,
    TextShimmer,
  },
};
</script>

<style>
</style>