<template>
  <div
    class="accordion"
    role="tablist"
  >
    <b-card
      no-body
      class="mb-1"
    >
      <b-card-header
        header-tag="header"
        class="p-0"
        role="tab"
      >
        <b-button
          v-b-toggle="accordionId"
          block
          class="project-dashboard-btn  d-flex align-items-center"
        >
          <span
            class="featherspan-dashboard"
            :style="cssVars"
          >
            <b-icon
              icon="Caret-right-fill"
              size="50"
              class="collapseicon-dashboard"
            /></span>
          <span
            class="project-title"
            :style="{ color: '#' + data[0].color }"
          ><!--Project-1 Name-->
            {{ data[0].name }} </span>
          <span
            class="total-task-project"
          >{{ data.length }} Tasks
          </span>
        </b-button>
      </b-card-header>
      <b-collapse
        :id="accordionId"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body class="p-0">
          <div class="card-body-listing">
            <b-row>
              <b-col cols="12">
                <b-table
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="data"
                  :fields="fields"
                  show-empty
                  class="
                    scrollable-element-dashboard
                    project-task-table dashboard-accordien-table
                    table-responsive
                    mb-0
                  "
                >
                  <!--If ticket then show project task type icon , user task title ,milestone & sprint other wise show task type title & user task title -->
                  <template #cell(name)="data">
                    <div class="d-flex align-items-center">

                      <span
                        v-if="
                          data.item &&
                            data.item.number &&
                            data.item.type === 'bug'
                        "
                        v-b-tooltip.hover.v-secondary
                        class="bug-fill-span"
                        title="Bug"
                      >
                        <b-icon icon="Bug-fill" />
                      </span>

                      <span
                        v-else-if="
                          data.item &&
                            data.item.number &&
                            data.item.type === 'story'
                        "
                        v-b-tooltip.hover.top="'Story'"
                        class="story-fill-span"
                      >
                        <b-icon icon="Bookmark-fill" />
                      </span>
                      <span
                        v-else
                        v-b-tooltip.hover.bottom="'Task'"
                        class="improvement-fill-span"
                      >
                        <img src="../../assets/images/Image/improvement.svg">
                      </span>
                      <div>
                        <p class="project-title-dashboard">
                          <!-- Search Inspirations for upcoming project Search -->
                          {{ data.item.task_title }}
                        </p>
                        <p class="milestone-sprint-dashboard">
                          <span
                            v-if=" data &&
                              data.item &&
                              data.item.milestone_title

                            "
                          ><!--Milestone 2-->
                            {{
                              data.item.milestone_title
                            }}
                          </span>

                          <span
                            v-if="
                              data.item &&
                                data.item.sprint_title
                            "
                          ><!--Sprint-->->
                            {{
                              data.item.sprint_title
                            }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </template>
                  <!--If ticket then show ticket other wise '-' -->
                  <template #cell(ticketnr)="data">
                    <div>
                      <span
                        v-if="data.item"
                        class="ticket"
                      ><!--ZT-12345-->
                        {{
                          data.item.task_number
                            ? data.item.task_number
                            : "-"
                        }}</span>
                      <span
                        v-if="!data.item"
                        class="ticket"
                      ><!--ZT-12345-->
                        -</span>
                    </div>
                  </template>
                  <!--If ticket then show status  other wise '-' -->
                  <template #cell(status)="data">
                    <div>
                      <span
                        v-if="data.item.status"
                        class="status-progress"
                      >{{ data.item.status_name }}</span>
                      <span
                        v-else
                      >-</span>
                    </div>
                  </template>
                  <!--If ticket then show timeestimated  other wise clock icon -->
                  <template #cell(timeestimated)="data">
                    <div
                      v-if="
                        data.item &&
                          data.item.task_number &&
                          data.item.estimated_hours
                      "
                    >
                      <span
                        class="esttime"
                      ><!--12hr 08m-->
                        {{
                          data.item.estimated_hours
                            | hoursInTimeFormat
                        }}</span>
                    </div>
                    <!-- if time is not define -->
                    <div
                      v-if="
                        !data.item ||
                          !data.item.task_number ||
                          !data.item.estimated_hours
                      "
                      class="clock-icon-span"
                    >
                      <feather-icon
                        v-b-tooltip.hover
                        icon="ClockIcon"
                        size="18"
                        title="Not Mentioned"
                      />
                    </div>
                  </template>
                  <!--If ticket then show timespented  font color black && if timespent is greater than timeestimated then font color red other wise green if timespent null then show  clock icon -->
                  <template #cell(timespent)="data">
                    <span
                      v-if="data.item && data.item.project_task_spent_minutes > 0"
                      class="esttime"
                      :style="
                        data.item.estimated_hours
                          ? data.item.project_task_spent_minutes / 60 >
                            data.item.estimated_hours
                            ? { color: 'red' }
                            : { color: 'green' }
                          : null
                      "
                    ><!--14hr 08m -->
                      {{
                        (data.item.project_task_spent_minutes / 60).toFixed(2)
                          | hoursInTimeFormat
                      }}</span>

                    <div
                      v-if="
                        data.item &&
                          data.item.project_task_spent_minutes == null
                      "
                      class="clock-icon-span"
                    >
                      <feather-icon
                        v-b-tooltip.hover
                        icon="ClockIcon"
                        size="18"
                        title="Not Time Spent"
                      />
                    </div>
                    <!--if not  have project task & total_time 0 then show icon-->

                    <div
                      v-if="
                        !data.item || data.item.project_task_spent_minutes == 0
                      "
                      class="clock-icon-span"
                    >
                      <feather-icon
                        v-b-tooltip.hover
                        icon="ClockIcon"
                        size="18"
                        title="Not Time Spent"
                      />
                    </div>
                    <!--if not  have project task & total_time > 0 then show TIME-->
                    <span
                      v-if="
                        !data.item && data.item.project_task_spent_minutes !== 0
                      "
                      class="esttime"
                    >
                      {{
                        (data.item.project_task_spent_minutes / 60).toFixed(2)
                          | hoursInTimeFormat
                      }}
                    </span>
                  </template>
                  <!--If ticket then show timespented  font color black && if due date  is future or present date  then font color green other wise red if due date null then show  clock icon -->
                  <!--If not ticket then show clock icon-->
                  <template #cell(duedate)="data">
                    <span
                      v-if="
                        data.item &&
                          data.item.task_number &&
                          data.item.due_date
                      "
                      class="esttime"
                      :style="
                        dateCompare(data.item.due_date)
                          ? { color: 'red' }
                          : { color: 'green' }
                      "
                    >{{ data.item.due_date | timeDayFormat }}
                      <!--Sep 14, 2022--></span>
                    <div
                      v-if="
                        !data.item ||
                          !data.item.task_number ||
                          !data.item.due_date
                      "
                      class="clock-icon-span"
                    >
                      <feather-icon
                        v-b-tooltip.hover
                        icon="CalendarIcon"
                        size="18"
                        title="Not Mentioned"
                      />
                    </div>
                  </template>
                  <!--if no ticket then proprity is low other wise proprity one of [low, medium ,high]-->
                  <template #cell(priority)="data">
                    <b-icon
                      v-if="
                        data.item &&
                          data.item.priority === 'high'
                      "
                      v-b-tooltip.hover.top="'High'"
                      icon="Flag-fill"
                      class="flag-fill-priority"
                    />
                    <b-icon
                      v-else-if="
                        data.item &&
                          data.item.priority === 'med'
                      "
                      v-b-tooltip.hover.top="'Medium'"
                      icon="Flag-fill"
                      class="flag-fill-priority-medium"
                    />
                    <b-icon
                      v-else
                      v-b-tooltip.hover.top="'Low'"
                      icon="Flag-fill"
                      class="flag-fill-priority-low"
                    />
                  </template>
                  <template #cell(ticket)="data">
                    <div
                      class="chervon-right"
                      style="pointer-events"
                      @click="redirectToTask(data.item)"
                    >
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="28"
                      />
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCol,
  BRow,
  BProgressBar,
  BCollapse,
  BCardHeader,
  BCardText,
  BCardBody,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ContentLoader } from 'vue-content-loader'
import * as moment from 'moment/moment'

export default {
  components: {
    BCard,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BDropdown,
    BDropdownItem,
    ContentLoader,
    BTabs,
    BTab,
    BCol,
    BRow,
    BProgressBar,
    BCollapse,
    BCardHeader,
    BCardText,
    BCardBody,
    BTable,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    /**
     * AccordionId for collapse Accordtion
     * @type {String}
     */
    accordionId: {
      type: String,
      required: true,
      default: null,
    },
    /**
     * color for  Accordtion button & project name
     * @type {String}
     */
    color: {
      type: String,
      required: false,
      default: '#000000',
    },
    /**
     * data for User Task list
     * @type {Array}
     */
    data: {
      type: Array,
      required: false,
      default: [],
    },
  },

  data() {
    return {
      perPage: null,
      currentPage: null,
      directives: {
        'b-tooltip': VBTooltip,
      },
      fields: [
        {
          key: 'name',
          label: '',
          tdClass: 'task-dashboard',
        },
        {
          key: 'ticketnr',
          label: 'Ticket no.',
          thStyle: 'min-width:120px',
        },
        {
          key: 'Status',
          thStyle: 'min-width:130px',
        },
        {
          key: 'timeestimated',
          label: ' Time Estimated',
          thStyle: 'min-width:150px',
        },
        {
          key: 'timespent',
          label: 'Time Spent',
          thStyle: 'min-width:150px',
        },
        {
          key: 'duedate',
          label: 'Due date',
          thStyle: 'min-width:130px',
        },
        {
          key: 'priority',
          thStyle: 'width:80px',
          tdClass: 'priority-td',
        },
        {
          key: 'ticket',
          label: '',
          thStyle: 'width:50px',
        },
      ],
    }
  },
  computed: {
    /**
     * dynmamic project color & accordion button color change
     */
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--color': this.color,
      }
    },
  },
  methods: {
    /**
     * Date Compare for Due date
     * @param {value} (due date)
     * @return true or false (due date > today)
     */
    dateCompare(value) {
      const dueDate = moment(value).format('YYYY-MM-DD')
      const currentDate = moment(new Date()).format('YYYY-MM-DD')

      return currentDate > dueDate
    },
    redirectToTask(data) {
      // this.$router.push(`projects/${projectId}/board/${TaskID}`)
      this.$router.push({
        name: 'projectTask',
        params: {
          id: 'board',
          id2: data.name,
          id3: data.project_id,
          id4: data.task_id,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
  },
}
</script>

<style>
</style>
