<template>
  <div>
    <activity-shimmer
      v-if="loader"
      class="generalshimmer"
    />
    <div
      v-else
      class="total-project-hour-dashboard dashtextShadow"
    >
      <div class="d-flex align-items-start justify-content-between">
        <div>
          <h3 class="mb-0 total-hours-time">
            Activity Logs
          </h3>
        </div>
      </div>
      <div class="hr-line-dashboard" />

      <div
        v-if="activityLogs.length"
        class="all-project-progress"
        style="min-height: 230px;"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(log, lindex) in activityLogs"
            :key="lindex"
            class="flex-column align-items-start"
            style="border: none"
          >
            <b-card-text class="">
              <div
                class="d-flex flex-wrap"
                v-html="log.description"
              />
            </b-card-text>
            <div>
              <small class="user-time-log-dashboard">{{
                log.created_at | dateFromNow
              }}</small>
            </div>

          </b-list-group-item>
        </b-list-group>
      </div>
      <!-- pagination section -->
      <Pagination
        v-if="totalCount>0"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        class="dashboard-pagination"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section complete here -->
      <div
        v-else
        class="d-flex align-items-center"
      >
        <img
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img no-data-dashboard mb-3"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import moment from 'moment'
import ActivityShimmer from '../shimmer/ActivityShimmer.vue'

export default {
  name: 'ActivityLogList',
  components: {
    ActivityShimmer,
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      activityLogs: [],
      loader: false,
      currentPage: 1,
      perPage: 20,
      totalCount: 0,
      start_date: moment().startOf('day').format('YYYY-MM-DD'),
      end_date: moment().endOf('day').format('YYYY-MM-DD'),
    }
  },
  watch: {
    userId() {
      this.userActivityLogs()
    },
    currentPage: {
      handler(value) {
        if (value) {
          this.userActivityLogs()
        }
      },
    },
  },
  mounted() {
    eventBus.$on('loadTaskListInDashboard', data => {
      if (data) {
        this.userActivityLogs()
      }
    })
  },
  methods: {
    /**
     * Get the login and filter users activty logs
     * @return logs
     */
    async userActivityLogs() {
      this.loader = true
      const input = {
        page: this.currentPage,
        per_page: this.perPage,
        user_id: this.userId ? this.userId : this.userInfo.id,
        pageName: 'generalDashboard',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/log',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.data = data
        this.activityLogs = data.activity
        this.totalCount = data.count
        this.loader = false
      }
    },
  },
}
</script>
