var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 mb-1"},[(_vm.loader)?_c('total-hour-shimmer',{staticClass:"generalshimmer mt-2"}):_c('div',{staticClass:"total-hours-dashboard mt-1  dashtextShadow"},[_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('div',[_c('h3',{staticClass:"mb-0 total-hours-time"},[_vm._v(" "+_vm._s(_vm._f("hoursInTimeFormat")((_vm.userTaskLogTotalHours / 60)))+" ")]),_c('p',{staticClass:"time-title-dashboard"},[_vm._v(" Total Time Spent ")])]),_c('b-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"icon-day"},[_vm._v(" "+_vm._s(_vm.taskDateRange))]),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"CalendarIcon"}})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){_vm.taskDateRange = 'Today'}}},[_vm._v(" Today ")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.taskDateRange = 'This Week'}}},[_vm._v(" This week ")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.taskDateRange = 'This Month'}}},[_vm._v(" This Month ")])],1)],1),_c('div',{staticClass:"total-hour-log",attrs:{"id":"element"}},[_vm._l((_vm.userTaskLogList),function(data,index){return _c('b-row',{key:index,staticClass:"ml-0 mr-0"},[_c('b-col',{staticClass:"p-0 total-hour-log-content",attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-items-start"},[_c('span',{class:data.project.work_type === 'productive'
                ? 'icon-watch'
                : 'icon-watch-disable'},[_c('feather-icon',{attrs:{"icon":"WatchIcon","size":"20"}})],1),_c('div',[_c('v-clamp',{style:({ whiteSpace: 'pre-line' }),attrs:{"autoresize":"","max-lines":2,"ellipsis":"...","location":"end"},scopedSlots:_vm._u([{key:"after",fn:function(ref){
                var toggle = ref.toggle;
                var clamped = ref.clamped;
                var expanded = ref.expanded;
return [(clamped == true || expanded == true)?_c('button',{staticClass:"see-more-btn",on:{"click":toggle}},[_vm._v(" "+_vm._s(clamped == true ? "see more" : "see less")+" ")]):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('span',{staticClass:"projectname-total-hour",style:({ color: '#' + data.project.color })},[_vm._v(" "+_vm._s(data.project.name))]),(data.project_task)?_c('span',{staticClass:"ticket-code-dashboard"},[_vm._v(_vm._s(data.project_task.number))]):_vm._e()],1)])]),_c('b-col',{staticClass:"total-hour-log-content text-right",attrs:{"cols":"3"}},[_c('span',{staticClass:"day-hour-dashboard"},[_vm._v(" "+_vm._s(_vm._f("hoursInTimeFormat")((data.total_time / 60)))+" ")])])],1)}),(_vm.userTaskLogList &&
          _vm.userTaskLogList.length == 0 &&
          _vm.loader == false
        )?_c('b-row',{staticClass:"ml-0 mr-0"},[_c('b-col',{staticClass:"pl-0 total-hour-log-content",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-start"},[_c('img',{staticClass:"no-data-img no-data-dashboard",attrs:{"src":require("../../assets/images/nodatafound/nodata.svg"),"alt":"no-data"}})])])],1):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }