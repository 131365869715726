<template>
  <div class="total-project-hour-dashboard hrinoutinfo newMxHeight">
    <div class="d-flex align-items-center justify-content-between hrFlatpicker">
      <div>
        <h3 class="mb-0 total-hours-time">
          <span>{{ title }}</span>
        </h3>
      </div>
      <div
        class="calendar mt-0 justify-content-center align-items-center calendarBg"
      >
        <div
          id="date-select"
          class="date-select mr-0"
        >
          <div class="date-dropdown">
            <VueDatePicker
              v-model="modalDate"
              type="month"
              no-header
            />
          </div>
        </div>
      </div>
    </div>
    <div class="all-project-progress hr-dashboard">
      <ul
        v-if="data"
        class="p-0 m-0"
      >
        <li>
          <table>
            <tbody>
              <tr
                v-for="(user, index) in data"
                :key="index"
              >
                <td class="empName">
                  <div
                    v-if="user.full_name"
                    class="d-flex align-items-center"
                  >
                    <b-avatar
                      :src="user.picture"
                      :text="user.full_name | avatarText"
                      :variant="randomVariant(1, data)"
                      class="mr-50"
                    />
                    <p
                      class="tbltxtWrap cursor-pointer"
                      @click="openUserProfile(user.id)"
                    >
                      {{ user.full_name }}
                    </p>
                  </div>
                </td>
                <td class="empEmail">
                  <div
                    v-if="user.email"
                    class="d-flex align-items-center "
                  >
                    <span>
                      <feather-icon
                        icon="MailIcon"
                        width="17"
                        height="17"
                        class="mr-50"
                      />
                    </span>
                    <p>{{ user.email }}</p>
                  </div>
                </td>
                <td
                  v-show="false"
                  class="empPhn"
                >
                  <div
                    v-if="user.phone"
                    class="d-flex align-items-center"
                  >
                    <span>
                      <feather-icon
                        icon="PhoneIcon"
                        width="17"
                        height="17"
                        class="ml-3"
                      /> </span>{{ user.phone }}
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-end justify-content-center"
                  >
                    <span>-</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
    </div>

    <div
      v-if="data && !data.length"
      class="d-flex align-items-center"
    >
      <img
        src="@/assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img no-data-dashboard"
      >
    </div>
  </div>
</template>
<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import * as moment from 'moment/moment'

const date = new Date()

export default {
  name: 'AvailbleResources',
  components: {
    VueDatePicker,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      activityLogs: [],
      perPage: 25,
      currentPage: 1,
      modalDate: date,
      month: moment(this.date, 'YYYY-MM').format('MM'),
      year: moment(this.date, 'YYYY-MM').format('YYYY'),
    }
  },
  watch: {
    modalDate() {
      this.applyFilter()
    },
  },
  mounted() {
    this.applyFilter()
  },
  methods: {
    applyFilter() {
      this.$emit('applyFilter', {
        month: moment(this.modalDate, 'YYYY-MM').format('MM'),
        year: moment(this.modalDate, 'YYYY-MM').format('YYYY'),
      })
    },
  },
}
</script>

<style></style>
