<template>
  <div>
    <progress-shimmer v-show="loader" />
    <div
      v-show="!loader"
      class="total-project-hour-dashboard hrinoutinfo member-watch-list dashtextShadow"
    >
      <div class="d-flex align-items-center justify-content-between watchlist-title">
        <div>
          <h3 class="mb-0 total-hours-time">
            <span>Member Watchlist</span>
          </h3>
        </div>
        <div
          v-if="memberLists.length"
          class="hrDaytime addmoreUser"
        >
          <b-button
            v-b-modal.memberWatchList
            variant="primary"
          >
            + Add Member
          </b-button>
        </div>
      </div>
      <div class="hr-line-dashboard" />
      <div
        class="mt-2 ml-2 memberWatchlistMxHeightSA"
        @scroll="closedropdown"
      >
        <!-- Draggable list start-->
        <div v-if="memberLists.length">
          <div class="horizontal-scroll-container">

            <b-list-group-item
              v-for="(member, index) in memberLists"
              :key="member.id"
              tag="li"
              class="member horizontal-list-item"
            >
              <b-card
                class="card-tiny-line-stats card-with-vertical-line d-flex"
                body-class="pb-50"
                style="min-height:220px"
                @click="memberId = member.id"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Remove'"
                  icon="XIcon"
                  size="18"
                  class="text-danger mr-2 close-icon svg-class"
                  role="button"
                  @click="deleteConfirmation(member, index)"
                />
                <div v-b-modal.memberSummaryPopup>
                  <b-row>
                    <b-col
                      cols="4"
                      lg="4"
                      xl="4"
                    >
                      <b-avatar
                        :src="member.picture"
                        :text="member.name | avatarText"
                        class="mr-50 b-avatar-width"
                      />
                    </b-col>
                    <b-col
                      cols="8"
                      lg="8"
                      xl="8"
                      class="pl-0"
                    >
                      <h5
                        class="font-weight-bolder"
                        style="text-wrap:wrap"
                      >
                        {{ member.full_name }}
                      </h5>
                      <h6
                        class="font-weight-bolder"
                        style="text-wrap:wrap"
                      >
                        Reporting to:
                        {{
                          member && member.users && member.users.full_name
                        }}
                      </h6>
                      <h6
                        class="font-weight-bolder"
                        style="text-wrap:wrap"
                      >
                        Manage By:
                        {{
                          member &&
                            member.manage_by_user &&
                            member.manage_by_user.full_name
                        }}
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col
                      cols="7"
                      lg="7"
                      xl="7"
                    >
                      <h6 class="font-weight-bolder">
                        First Clock In Today
                      </h6>
                      <h6 class="font-weight-bolder">
                        Last Clock In Today
                      </h6>
                      <h6 class="font-weight-bolder">
                        Last Clock Out Today
                      </h6>
                    </b-col>
                    <b-col cols="4">
                      <h6>
                        {{ getFormattedTime(member.clock, 'firstIn') }}
                      </h6>

                      <h6>
                        {{ getFormattedTime(member.clock, 'lastIn') }}
                      </h6>

                      <h6>
                        {{ getFormattedTime(member.clock, 'lastOut') }}
                      </h6>

                    </b-col>
                  </b-row>

                </div>
              </b-card>
            </b-list-group-item>
          </div>
        </div>
        <!-- Draggable list end-->
        <div
          v-else
          class="addmoreUser d-flex flex-column align-items-center justify-content-center center-btn"
        >
          <b-button
            v-b-modal.memberWatchList
            variant="primary"
          >
            +Add Member
          </b-button>
          <p>
            Customize your watchlist by adding a member based on your preference
          </p>
        </div>
      </div>
    </div>

    <!-- model code start-->
    <div>
      <validation-observer ref="memberWatchListRuls">
        <!-- modal vertical center -->
        <b-modal
          id="memberWatchList"
          centered
          content-class="watchlist-model"
          :no-close-on-backdrop="true"
          @hidden="clearData"
        >
          <template #modal-title>
            <span>Add Member to your watchlist</span>
          </template>
          <div class="p-2">
            <div>
              <b-row class="align-items-center">
                <b-col cols="12">
                  <div>
                    <!-- member select-->
                    <b-form-group>
                      <label for="memberName">Member Name <span class="text-danger">*</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="Member"
                        rules="required"
                      >
                        <v-select
                          id="memberName"
                          v-model="member_id"
                          label="name"
                          class="select-size-lg"
                          name="member"
                          multiple
                          :options="usersList"
                          :reduce="(u) => u.id"
                          :close-on-select="false"
                          placeholder="Select Member Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <div>
                <button
                  class="commonBtn mr-2"
                  @click="validationForm()"
                >
                  Add
                </button>
                <button
                  class="commonBtn-outline"
                  @click="$bvModal.hide('memberWatchList')"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </b-modal>
      </validation-observer>
    </div>
    <!-- model code end-->
    <member-summary :member-id="memberId" />
  </div>
</template>

<script>
import {
  BButton, BModal, VBModal, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ProgressShimmer from '../shimmer/ProgressShimmer.vue'
import MemberSummary from './memberSummary.vue'

export default {
  name: 'ProjectWatchlist',
  components: {
    ProgressShimmer,
    BModal,
    BButton,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    MemberSummary,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      memberLists: [],
      loader: false,
      member_id: [],
      totalCount: 0,
      isShowTooltip: true,
      memberId: null,
    }
  },
  computed: {
    /** Filter out exits member */
    usersList() {
      const userList = this.$store.state.app.usersList
      const memberIds = new Set(this.memberLists.map(member => member.id))
      return this.userInfo && this.userInfo.role === 'EMP'
        ? userList.filter(
          member => !memberIds.has(member.id) && member.role !== 'SA' && member.id !== this.userInfo.id && (member.reporting_to == this.userInfo.id || member.manage_by == this.userInfo.id),
        )
        : userList.filter(member => !memberIds.has(member.id) && member.id !== this.userInfo.id)
    },
  },
  mounted() {
    this.memberWatchlist()
  },
  methods: {
    closedropdown() {
      const visibleMenu = this.$refs.foo.find(
        element => element.visible === true,
      )
      if (visibleMenu) {
        const hoverElements = document.querySelectorAll(
          '.total-member-hour-dashboard .dropdown-menu .dropdown-item:hover',
        )
        if (hoverElements.length === 0) {
          visibleMenu.hide()
        }
      }
    },
    /**
     * Get member watchlist
     */
    async memberWatchlist() {
      this.loader = true

      const response = await this.getHTTPPostResponse(
        'admin/master/user/member-watch-list',
        {},
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.memberLists = data.member_lists
        this.totalCount = data.count
        this.loader = false
      }
    },

    /**
     * check the form validation
     * @returns if success true then call createWatchlist Method
     */
    validationForm() {
      this.$refs.memberWatchListRuls.validate().then(success => {
        if (success) {
          this.createWatchList()
        }
      })
    },

    /** Create new member watch list */
    async createWatchList() {
      this.loader = true
      const input = {
        member_ids: this.member_id,
      }

      const response = await this.getHTTPPostResponse(
        'admin/master/user/create-member-watch-list',
        input,
        true,
      )
      if (response && response.status === 200) {
        this.memberWatchlist()
        this.$root.$emit('bv::toggle::modal', 'memberWatchList')
      }
    },

    /** Confirmation before delete member watchlist */
    async deleteConfirmation(data, index) {
      const title = 'Do you want to remove this member from the watch list?'
      const conformation = await this.conformationAlert(false, title)
      if (conformation.isConfirmed === true) {
        this.deleteMemberWatchlist(data.id, index)
      }
    },

    /** delete member watchlist */
    async deleteMemberWatchlist(id, index) {
      const response = await this.getHTTPDeleteResponse(
        `admin/master/user/delete-member-watch-list/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.memberLists.splice(index, 1)
      }
    },

    /** Clear model data & reset validation  */
    clearData() {
      this.member_id = []
      this.$refs.memberWatchListRuls.reset()
    },

  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables.scss";

.member-watch-list {
  .hr-line-dashboard {
    margin: 0px 0px;
  }

  .watchlist-title {
    padding: 25px 25px 15px;
  }

  .list-group {
    max-height: 290px;
  }

  .list-group-item {
    transition: all 1s;

    p {
      max-width: 200px;
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .memberWatchlistMxHeightSA {
    min-height: 250px !important;
  }

  .watchlistMxHeightEMP {
    min-height: 335px !important;
  }

  .board-active-inactive {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 40px;
  }

  .center-btn {
    position: relative;
    top: 100px;

    p {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }
  }
}

.watchlist-model {
  position: relative;
  padding: 0 !important;
  border-radius: 6px !important;

  .vs__selected {
    font-size: 1.75 * $rem;
    background-color: $primary-color;
    color: $white;
    padding: 5px;
    border: transparent;
    text-transform: capitalize;

    svg {
      fill: $white;
    }
  }

  .vs__selected-options {
    input {
      &::placeholder {
        color: #b9b9c3;
      }
    }
  }

  .modal-header {
    .close {
      position: absolute;
      right: 30px;
      top: 20px;
      z-index: 9;
      padding: 0;
      box-shadow: none;
      background-color: transparent;
      transition: 0.5s ease-in-out;

      &:hover {
        transform: rotate(180deg);
      }
    }

    .modal-title {
      font-size: 18px;
      padding-top: 12px;
      padding-left: 12px;
      color: $primary-color;
    }
  }

  .modal-body {
    margin: 0;
    padding-top: 10px;
  }

  .modal-footer {
    display: none;
  }
}

.horizontal-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;

    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgba(184, 184, 184, 0.3);
      background-color: transparent;
      border-radius: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 5px rgba(184, 184, 184, 0.3);
    border-radius: 10px;
    background-color: $scroller-color;
  }
}

.horizontal-list-item {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  /* Optional: add some space between items */
  min-width: 375px;
  /* Set a minimum width for each item */
}

.card-with-vertical-line {
  border-left: 3px solid #007bff;
  /* Adjust color and width as needed */
  padding-left: 10px;
  /* Optional: Adds space between the border and card content */
}

.member {
  padding: 0rem;
  background-color: #ffffff;
  border: none;
}

.b-avatar-width {
  width: 4.5rem;
  height: 4.5rem;
}

.list-group .list-group-item i,
.list-group .list-group-item .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.horizontal-scroll-container .list-group .list-group-item:hover {
  background-color: none;
}

.svg-class {
  margin: 0 !important;
  position: absolute;
  top: 7px;
  right: 12px;
}
</style>
