<template>
  <div
    class="total-project-hour-dashboard hrinoutinfo newMxHeightClock hruseractivity overflow-hidden"
  >
    <div class="hrFlatpicker pb-50">
      <h3 class="mb-0 total-hours-time">
        Employee’s In - Out Activity
      </h3>
      <div class="d-flex justify-content-between hrsearchDrop">
        <div class="hrSearchbox">
          <b-form-input
            v-model="debouncedSearch"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
        <div>
          <b-form-select
            v-model="perPage"
            :options="['25', '50', '100', 'All']"
            class="hrinoutdropdown"
          />
        </div>
      </div>
    </div>
    <div
      v-if="users.length"
      class="all-project-progress hr-dashboard"
      style=" max-height: 680px;"
    >
      <ul class="m-0 p-0 float-left w-100">
        <li
          v-for="(log, lindex) in users"
          :key="lindex"
          class=""
          style="border: none"
        >
          <div
            class="d-flex"
          >
            <b-avatar
              :src="log && log.picture"
              :text="log.full_name.split(' ').slice(0, 2).join(' ') | avatarText"
              :variant="randomVariant(1, users.length)"
              class="mr-1"
            />
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div
                class="activityName full_name cursor-pointer"
                @click="openUserProfile(log.id)"
              >
                {{ log.full_name }}
              </div>
              <div class="activityHours">
                <p
                  class="user-time-log-dashboard"
                  :style=" log && log.in_date && !log.out_time ? 'color:#2178fb' :null"
                >
                  {{ log && log.in_date ? getLastActivity(log):'No Activity' }}

                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div
      v-if="!users.length"
      class="d-flex align-items-center"
    >
      <img
        src="@/assets/images/nodatafound/nodata.svg"
        alt="no-data"
      >
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import debounce from 'lodash/debounce'

export default {
  name: 'ClockInOutActivity',
  data() {
    return {
      users: [],
      search: null,
      perPage: 'All',
      currentPage: 1,

    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.userActivityLogs()
      }, 500),
    },
  },

  watch: {
    perPage() {
      this.userActivityLogs()
    },
  },
  mounted() {
    this.userActivityLogs()
  },
  methods: {
    async userActivityLogs() {
      const input = {
        page: this.perPage == 'All' ? 1 : this.currentPage,
        per_page: this.perPage == 'All' ? null : this.perPage,
        search: this.search,
        is_active: true,
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user/user-list-with-latest-clock',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.users = data.users
        this.load_inout_activity = false
      }
    },

    getLastActivity(log) {
      if (log.in_date) {
        if (moment(log.in_date, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
          return log.out_time ? `Clock out at ${log.out_time}` : `Clock in at ${log.in_time}`
        }
        return log.out_time ? `Clock out ${log.out_date} at ${log.out_time}` : `Clock in ${log.in_date} at ${log.in_time}`
      }
    },
  },
}
</script>
