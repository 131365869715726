<template>
  <div class="h-100 mb-1">
    <total-hour-shimmer
      v-if="loader"
      class="generalshimmer mt-2"
    />
    <div
      v-else
      class="total-hours-dashboard mt-1  dashtextShadow"
    >
      <div class="d-flex align-items-start justify-content-between">
        <div>
          <h3 class="mb-0 total-hours-time">
            {{ (userTaskLogTotalHours / 60) | hoursInTimeFormat }}
          </h3>
          <p class="time-title-dashboard">
            Total Time Spent
          </p>
        </div>
        <!--Filters start-->
        <b-dropdown right>
          <template #button-content>
            <span class="icon-day"><!--Today-->
              {{ taskDateRange }}</span>
            <feather-icon
              icon="CalendarIcon"
              class="icon"
            />
          </template>
          <b-dropdown-item @click="taskDateRange = 'Today'">
            Today
          </b-dropdown-item>
          <b-dropdown-item @click="taskDateRange = 'This Week'">
            This week
          </b-dropdown-item>
          <b-dropdown-item @click="taskDateRange = 'This Month'">
            This Month
          </b-dropdown-item>
        </b-dropdown>
        <!--Filters end-->
      </div>
      <!-- user Task Log  start-->
      <div
        id="element"
        class="total-hour-log"
      >
        <b-row
          v-for="(data, index) in userTaskLogList"
          :key="index"
          class="ml-0 mr-0"
        >
          <b-col
            cols="9"
            class="p-0 total-hour-log-content"
          >
            <div class="d-flex align-items-start">
              <span
                :class="data.project.work_type === 'productive'
                  ? 'icon-watch'
                  : 'icon-watch-disable'
                "
              ><feather-icon
                icon="WatchIcon"
                size="20"
              /></span>

              <div>

                <v-clamp
                  autoresize
                  :max-lines="2"
                  ellipsis="..."
                  location="end"
                  :style="{ whiteSpace: 'pre-line' }"
                >
                  {{ data.title }}
                  <template #after="{ toggle, clamped, expanded }">
                    <button
                      v-if="clamped == true || expanded == true"
                      class="see-more-btn"
                      @click="toggle"
                    >
                      {{ clamped == true ? "see more" : "see less" }}
                    </button>
                  </template>
                </v-clamp>
                <!-- </p> -->
                <span
                  class="projectname-total-hour"
                  :style="{ color: '#' + data.project.color }"
                ><!--Project Name-->
                  {{ data.project.name }}</span>
                <span
                  v-if="data.project_task"
                  class="ticket-code-dashboard"
                ><!--ZT-12345{{data.project_task.number}}-->{{
                  data.project_task.number
                }}</span>
              </div>
            </div>
          </b-col>
          <b-col
            cols="3"
            class="total-hour-log-content text-right"
          ><span class="day-hour-dashboard"><!--02hr 05m-->
            {{ (data.total_time / 60) | hoursInTimeFormat }}
          </span></b-col><!-- user Task Log List end-->
        </b-row>
        <!-- If no user Task Log-->
        <b-row
          v-if="userTaskLogList &&
            userTaskLogList.length == 0 &&
            loader == false
          "
          class="ml-0 mr-0"
        >
          <b-col
            cols="12"
            class="pl-0 total-hour-log-content"
          >
            <div class="d-flex align-items-start">
              <img
                src="../../assets/images/nodatafound/nodata.svg"
                alt="no-data"
                class="no-data-img no-data-dashboard"
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
import { eventBus } from '@/main'
import moment from 'moment'
import TotalHourShimmer from '../shimmer/totalHourShimmer.vue'

export default {
  name: 'DashboardUserLogList',
  components: {
    VClamp,
    TotalHourShimmer,
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      userTaskLogTotalHours: null,
      taskDateRange: 'Today',
      userTaskLogList: [],
      loader: false,
      start_date: moment().startOf('day').format('YYYY-MM-DD'),
      end_date: moment().endOf('day').format('YYYY-MM-DD'),
    }
  },
  watch: {
    userId() {
      this.taskDateRange = 'Today'
      this.userTaskList()
    },

    taskDateRange(nv) {
      const startOf = nv != 'Today' ? nv.split(' ')[1].toLowerCase() : 'day'
      this.start_date = moment().startOf(startOf).format('YYYY-MM-DD')
      this.end_date = moment().endOf(startOf).format('YYYY-MM-DD')
      this.userTaskList()
    },
  },
  mounted() {
    eventBus.$on('loadTaskListInDashboard', data => {
      if (data) {
        this.userTaskList()
      }
    })
  },
  methods: {
    /**
     * User Task Log List
     * @return userTaskLogList & userTaskLogTotalHours
     */
    async userTaskList() {
      this.loader = true
      const input = {
        start_date: this.start_date,
        end_date: this.end_date,
        user_id: this.userId,
      }

      const response = await this.getHTTPPostResponse(
        'admin/master/work-dashboard/user-task-log-list',
        input,
        false,
      )

      if (response && response.data) {
        this.userTaskLogList = response.data.user_task_log_list
        this.userTaskLogTotalHours = response.data.total_minutes

        this.loader = false
      }
    },
  },
}
</script>
