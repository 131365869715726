<template>
  <div :class="['newMxHeight', userInfo.role != 'SA' && !userInfo.is_resource_managing ? 'h-100' : null]">
    <progress-shimmer v-if="loader" />
    <div
      v-else
      class="total-project-hour-dashboard hrinoutinfo watchlist"
      :class="userInfo && userInfo.role == 'SA' ? 'newMxHeight mb-2' : 'dashtextShadow'"
    >
      <div class="d-flex align-items-center justify-content-between watchlist-title">
        <div>
          <h3 class="mb-0 total-hours-time ">
            <span>Projects Watchlist</span>
          </h3>
        </div>
        <div
          v-if="totalCount"
          class="hrDaytime addmoreUser"
        >
          <b-button
            v-b-modal.watchlist
            variant="primary"
          >
            + Add Project
          </b-button>
        </div>
      </div>
      <div
        v-if="!totalCount"
        class="hr-line-dashboard"
      />
      <div
        class="all-project-progress hr-dashboard"
        :class="userInfo && userInfo.role == 'SA' || (userInfo.role == 'EMP' && userInfo.is_resource_managing) ? 'watchlistMxHeightSA' : 'watchlistMxHeightEMP'"
        @scroll="closedropdown"
      >
        <!-- Draggable list start-->
        <div v-if="totalCount">
          <draggable
            v-model="projectsList"
            class="list-group cursor-move"
            tag="ul"
            @change="updateDisplayOrder"
          >
            <transition-group
              type="transition"
              name="flip-list"
            >
              <b-list-group-item
                v-for="(project) in projectsList"
                :key="project.id"
                tag="li"
              >
                <div class="align-items-center d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      :text="project.name | avatarText"
                      class="mr-50"
                      :style="{ 'background-color': '#' + project.color }"
                    />
                    <b-tooltip
                      triggers="hover"
                      :target="project.id"
                      :title="project.name"
                      :delay="{ show: 500, hide: 5 }"
                    />

                    <p
                      :id="project.id"
                      class="tbltxtWrap d-inline-block"
                      :style="{ color: '#' + project.color }"
                    >
                      {{ project.name }}
                    </p>
                  </div>
                  <div class="board-active-inactive d-flex align-items-center flex-wrap justify-content-end">
                    <!-- Redirect to Summary , list & board view  -->
                    <div class="d-flex align-items-center justify-content-start flex-wrap">

                      <feather-icon
                        v-b-tooltip.hover.top="'Board'"
                        icon="ClipboardIcon"
                        size="18"
                        class="mr-1 text-dark"
                        role="button"
                        @click="reDirectTab('board', project)"
                      />

                      <feather-icon
                        v-b-tooltip.hover.top="'List'"
                        icon="ListIcon"
                        size="18"
                        class="mr-1 text-dark"
                        role="button"
                        @click="reDirectTab('tasklist', project)"
                      />

                      <!-- <feather-icon
                        v-b-tooltip.hover.top="'QAssure'"
                        icon="FileTextIcon"
                        class="text-dark"
                        size="18"
                        role="button"
                        @click="reDirectTab('qassure')"
                      /> -->
                      <span
                        v-b-tooltip.hover.top="'QAssure'"
                        class="cursor-pointer"
                        @click="reDirectTab('qassure', project)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="m15.65 4.263l.813.101a3.75 3.75 0 0 1 3.287 3.721v10.49a3.641 3.641 0 0 1-3.191 3.613c-3.028.377-6.09.377-9.118 0a3.641 3.641 0 0 1-3.191-3.613V8.085a3.75 3.75 0 0 1 3.287-3.72l.813-.102A2.751 2.751 0 0 1 11 2.25h2a2.75 2.75 0 0 1 2.65 2.013Zm-7.4 1.524l-.528.066A2.25 2.25 0 0 0 5.75 8.085v10.49c0 1.08.805 1.991 1.876 2.125a35.39 35.39 0 0 0 8.747 0a2.141 2.141 0 0 0 1.877-2.125V8.085a2.25 2.25 0 0 0-1.972-2.232l-.528-.066V7a.75.75 0 0 1-.75.75H9A.75.75 0 0 1 8.25 7V5.787ZM9.75 5c0-.69.56-1.25 1.25-1.25h2c.69 0 1.25.56 1.25 1.25v1.25h-4.5V5Z"
                            clip-rule="evenodd"
                          />
                          <path
                            fill="currentColor"
                            d="M15.75 11.75A.75.75 0 0 0 15 11H9a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 .75-.75Zm-1 3A.75.75 0 0 0 14 14H9a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 .75-.75Z"
                          />
                        </svg>
                      </span>

                    </div>
                    <div class="d-flex align-items-center">
                      <feather-icon
                        v-b-tooltip.hover.top="'Remove'"
                        icon="MinusCircleIcon"
                        size="18"
                        class="text-danger mr-2"
                        role="button"
                        @click="deleteConfirmation(project)"
                      />

                      <span class="moreAction">
                        <b-dropdown
                          id="dropdown-left"
                          ref="foo"
                          text=""
                          variant="primary"
                          @show="isShowTooltip = false"
                          @hide="isShowTooltip = true"
                        >
                          <template v-slot:button-content>
                            <button>
                              <feather-icon
                                v-if="!isShowTooltip"
                                icon="ArrowRightIcon"
                                size="20"
                              />
                              <feather-icon
                                v-else
                                v-b-tooltip.hover.top="'More'"
                                icon="ArrowRightIcon"
                                size="20"
                              />

                            </button>
                          </template>
                          <b-dropdown-item @click="reDirectTab('summary', project)">Summary</b-dropdown-item>
                          <b-dropdown-item @click="reDirectTab('team', project)">Team</b-dropdown-item>
                          <b-dropdown-item @click="reDirectTab('master-plan', project)">Master Plan</b-dropdown-item>
                          <b-dropdown-item @click="reDirectTab('note', project)">Notes</b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </transition-group>
          </draggable>
        </div>
        <!-- Draggable list start-->
        <div
          v-else
          class="addmoreUser d-flex flex-column align-items-center justify-content-center center-btn"
        >
          <b-button
            v-b-modal.watchlist
            variant="primary"
          >
            + Add Project
          </b-button>
          <p>Customize your watchlist by adding a project based on your preference</p>
        </div>

      </div>
    </div>
    <!-- model code start-->
    <div>
      <validation-observer ref="projectWatchListRuls">
        <!-- modal vertical center -->
        <b-modal
          id="watchlist"
          centered
          content-class="watchlist-model"
          :no-close-on-backdrop="true"
          @shown="openModel"
          @hidden="clearData"
        >
          <template #modal-title>
            <span>Add projects to your watchlist</span>
          </template>
          <div class="p-2">
            <div>
              <b-row class="align-items-center">
                <b-col cols="12">
                  <div>
                    <!-- project select-->
                    <b-form-group> <label for="projectName">Project Name <span class="text-danger">*</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="Project"
                        rules="required"
                      >
                        <v-select
                          id="projectName"
                          v-model="project_id"
                          label="name"
                          class="select-size-lg"
                          name="project"
                          multiple
                          :options="filteredNewProjectList"
                          :reduce="(u) => u.id"
                          :close-on-select="false"
                          placeholder="Select Project Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <div class="text-center">
                <button
                  class="commonBtn mr-2"
                  @click="validationForm()"
                >
                  Add
                </button>
                <button
                  class="commonBtn-outline"
                  @click="$bvModal.hide('watchlist')"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </b-modal>
      </validation-observer>
    </div>
    <!-- model code end-->
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import draggable from 'vuedraggable'
import ProgressShimmer from '../shimmer/ProgressShimmer.vue'

export default {
  name: 'ProjectWatchlist',
  components: {
    ProgressShimmer,
    BModal,
    BButton,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    draggable,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      projectsList: [],
      loader: false,
      project_id: [],
      totalCount: 0,
      isShowTooltip: true,
    }
  },
  computed: {
    /** Filter out exits project */
    filteredNewProjectList() {
      const projectList = this.$store.state.app.productiveProjectList
      const projectIds = new Set(this.projectsList.map(project => project.id))
      return projectList.filter(project => !projectIds.has(project.id) && project.code !== 'ROJ')
    },
  },
  mounted() {
    this.projectWatchlist()
  },
  methods: {
    closedropdown() {
      const visibleMenu = this.$refs.foo.find(element => element.visible === true)
      if (visibleMenu) {
        const hoverElements = document.querySelectorAll('.total-project-hour-dashboard .dropdown-menu .dropdown-item:hover')
        if (hoverElements.length === 0) {
          visibleMenu.hide()
        }
      }
    },

    /**
     * Get project watchlist
     */
    async projectWatchlist() {
      this.loader = true

      const response = await this.getHTTPPostResponse(
        'admin/master/user/watch-list',
        {},
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.projectsList = data.project_list
        this.totalCount = data.count
        this.loader = false
      }
    },
    /* view project */
    reDirectTab(tab, project) {
      this.$router.push({
        name: 'projectTask',
        params: {
          id: tab,
          id2: project.name,
          id3: project.id,
          id4: tab === 'qassure' ? 'test-suite' : null,
          page_name: 'dashboard',
        },
      })
    },

    /** Update project watchlist display order */
    async updateDisplayOrder(item) {
      const input = {
        project_id: item.moved.element.id,
        display_order: item.moved.newIndex + 1,
      }

      const response = await this.getHTTPPostResponse(
        'admin/master/user/update-order',
        input,
      )

      if (response && response.status === 200) {
        // code
      }
    },

    /**
     * check the form validation
     * @returns if success true then call createWatchlist Method
     */
    validationForm() {
      this.$refs.projectWatchListRuls.validate().then(success => {
        if (success) {
          this.createWatchList()
        }
      })
    },

    /** Create new project watch list */
    async createWatchList() {
      this.loader = true
      const input = {
        project_id: this.project_id,
      }

      const response = await this.getHTTPPostResponse(
        'admin/master/user/create-project-watch-list',
        input,
        true,
      )
      if (response && response.status === 200) {
        this.projectWatchlist()
        this.$root.$emit('bv::toggle::modal', 'watchlist')
      }
      this.loader = false
    },

    openModel() {
      if (this.$store.state.app.productiveProjectList && this.$store.state.app.productiveProjectList.length === 0) {
        this.getProjectsData()
      }
    },

    /** Confirmation before delete project watchlist */
    async deleteConfirmation(data) {
      this.$swal({
        title: 'Do you want to remove this project from the watch list?',
        icon: 'info',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteProjectWatchlist(data.id)
        }
      })
    },

    /** delete project watchlist */
    async deleteProjectWatchlist(id) {
      const response = await this.getHTTPDeleteResponse(
        `admin/master/user/delete-project-watch-list/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.projectWatchlist()
      }
    },

    /** Clear model data & reset validation  */
    clearData() {
      this.project_id = []
      this.$refs.projectWatchListRuls.reset()
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables.scss";

.watchlist {
  .hr-line-dashboard {
    margin: 0px 0px;
  }

  .watchlist-title {
    padding: 25px 25px 15px;
  }

  .list-group {
    max-height: 290px;
  }

  .list-group-item {
    transition: all 1s;

    p {
      max-width: 200px;
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .watchlistMxHeightSA {
    min-height: 315px !important;
  }

  .watchlistMxHeightEMP {
    min-height: 335px !important;
  }

  .board-active-inactive {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 40px;
  }

  .center-btn {
    position: relative;
    top: 100px;

    p {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }
  }
}

/* Style for dropdown button */
.moreAction {
  button {
    background-color: #fff;
    color: #fff;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      box-shadow: none !important;
    }
  }

  /* Style for dropdown items */
  .dropdown-menu {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .dropdown-menu a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 10px;
  }

  //b-dropdown btn-group dropdown
  .dropdown-toggle {
    background-color: #fff !important;
    padding: 0px;
  }

  .dropdown-menu a:hover {
    background-color: #f0f0f0;
  }

  .feather {
    color: $primary-color !important;
  }
}

.watchlist-model {
  position: relative;
  padding: 0 !important;
  border-radius: 6px !important;

  .vs__selected {
    font-size: 1.75 * $rem;
    background-color: $primary-color;
    color: $white;
    padding: 5px;
    border: transparent;
    text-transform: capitalize;

    svg {
      fill: $white;
    }
  }

  .vs__selected-options {
    input {
      &::placeholder {
        color: #B9B9C3;
      }
    }
  }

  .modal-header {
    .close {
      position: absolute;
      right: 30px;
      top: 20px;
      z-index: 9;
      padding: 0;
      box-shadow: none;
      background-color: transparent;
      transition: 0.5s ease-in-out;

      &:hover {
        transform: rotate(180deg);
      }
    }

    .modal-title {
      font-size: 18px;
      padding-top: 12px;
      padding-left: 12px;
      color: $primary-color;
    }
  }

  .modal-body {
    margin: 0;
    padding-top: 10px;
  }

  .modal-footer {
    display: none;
  }
}
</style>
