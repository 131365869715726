<template>
  <div
    class="hrdashboardInfo"
    style="padding-bottom: 0px;"
  >
    <b-row class="mx-0">
      <!-- Full Time Available Resources -->
      <b-col
        cols="12 pl-0"
        lg="6"
        xl="6"
        class="custom-class"
      >
        <AvailbleResources
          :title="'Full Time Available Resources'"
          :data="fullTimeResources"
          @applyFilter="fullTimeAvailableResources($event)"
        />
      </b-col>
      <!-- Part Time Available Resources -->
      <b-col
        cols="12 p-0"
        lg="6"
        xl="6"
        class="custom-class"
      >
        <AvailbleResources
          :title="'Part Time Available Resources'"
          :data="partTimeResources"
          @applyFilter="partTimeAvailableResources($event)"
        />
      </b-col>
    </b-row>
    <b-row class="mx-0 mt-1">
      <b-col
        cols="12 pl-0"
        lg="6"
        xl="6"
        class="custom-class"
      >
        <LessEightHr />
      </b-col>
      <b-col
        cols="12 pl-0 pr-0"
        lg="6"
        xl="6"
        class="custom-class"
      >
        <ProjectWatchlist />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import AvailbleResources from '@/components/Resources/AvailableResources.vue'
import ProgressShimmer from './shimmer/ProgressShimmer.vue'
import ProjectWatchlist from './Dashboard/projectWatchlist.vue'
import LessEightHr from '../components/Dashboard-accordion/lessEightHr.vue'

export default {
  name: 'ManagerDashBoard',
  components: {
    ProgressShimmer,
    AvailbleResources,
    VueDatePicker,
    LessEightHr,
    ProjectWatchlist,
  },
  data() {
    return {
      fullTimeResources: [],
      partTimeResources: [],
    }
  },
  methods: {
    async fullTimeAvailableResources($event) {
      const input = {
        is_active: true,
        month: $event.month
          ? $event.month
          : moment(this.date, 'YYYY-MM').format('MM'),
        year: $event.year
          ? $event.year
          : moment(this.date, 'YYYY-MM').format('MM'),
        allocation: 'available',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/my-team',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.totalCount = data.count
        this.fullTimeResources = data.users
      }
    },

    async partTimeAvailableResources($event) {
      const input = {
        is_active: true,
        month: $event.month
          ? $event.month
          : moment(this.date, 'YYYY-MM').format('MM'),
        year: $event.year
          ? $event.year
          : moment(this.date, 'YYYY-MM').format('MM'),
        allocation: 'parttime',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/my-team',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.totalCount = data.count
        // console.log("users",data.users);
        this.partTimeResources = data.users
      }
    },
  },
}
</script>

<style>
  @media only screen and (max-width: 768px) {
  .custom-class {
    /* Your styles for screen width less than or equal to 700px */
    margin-top: 10px;
    padding-right: 0rem !important;
    padding-left:1rem !important;
  }
}


</style>
