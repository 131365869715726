<template>
  <div :class="{'total-project-hour-dashboard dashtextShadow':!loader}">
    <progress-shimmer
      v-if="loader"
      class="generalshimmer"
    />

    <div
      v-else
    >
      <div class="d-flex align-items-start justify-content-between">
        <div>
          <h3 class="mb-0 total-hours-time">
            <!--120hr 20m-->
            {{ (totalProjectMinutes / 60) | hoursInTimeFormat }}
          </h3>
          <p class="time-title-dashboard">
            Total Time Spent
          </p>
        </div>
        <!--Filters start-->
        <b-dropdown right>
          <template #button-content>
            <span
              class="icon-day"
            ><!--Today-->
              {{ taskDateRange }}</span>
            <feather-icon
              icon="CalendarIcon"
              class="icon"
            />
          </template>
          <b-dropdown-item
            @click="taskDateRange = 'Today'"
          >Today
          </b-dropdown-item>
          <b-dropdown-item
            @click="taskDateRange = 'This Week'"
          >This week</b-dropdown-item>
          <b-dropdown-item
            @click="taskDateRange = 'This Month'"
          >This Month</b-dropdown-item>
        </b-dropdown>
        <!--Filters start-->
      </div>
      <div class="hr-line-dashboard" />
      <div class="all-project-progress">
        <div
          v-for="(progressData, index) in userProjectLogList"
          :key="index"
        >
          <div class="total-project-hour-user">
            <div class="d-flex align-items-center">
              <p
                class="user-projects-name"
                :style="{ color: '#' + progressData.color }"
              >
                <!--Project Name-1-->
                {{ progressData.name }}
              </p>
              <span
                class="user-total-hour"
              ><!--70hr 23m-->
                {{
                  (progressData.total_minutes / 60) | hoursInTimeFormat
                }}
              </span>
            </div>

            <div class="new-progress-bar">
              <div
                class="project-progress-custom"
                :style="{ backgroundColor: '#' + progressData.color }"
              />
              <div
                class="progress-done"
                :style="{
                  width:
                    (progressData.total_minutes / totalProjectMinutes) *
                    100 +
                    '%',
                  backgroundColor: '#' + progressData.color,
                }"
              />
            </div>
          </div>
        </div>
        <!--If there is no progress data -->
        <div
          v-if="
            userProjectLogList &&
              userProjectLogList.length == 0 &&
              loader == false
          "
        >
          <div class="total-project-hour-user">
            <div class="d-flex align-items-center">
              <img
                src="../../assets/images/nodatafound/nodata.svg"
                alt="no-data"
                class="no-data-img no-data-dashboard"
              >
            </div>
          </div>
        </div>

        <!-- custom progress done -->
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import moment from 'moment'
import ProgressShimmer from '../shimmer/ProgressShimmer.vue'

export default {
  name: 'DashboardUserLogList',
  components: {
    ProgressShimmer,
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      taskDateRange: 'Today',
      userProjectLogList: [],
      totalProjectMinutes: null,
      loader: false,
      start_date: moment().startOf('day').format('YYYY-MM-DD'),
      end_date: moment().endOf('day').format('YYYY-MM-DD'),
    }
  },
  watch: {
    userId() {
      this.taskDateRange = 'Today'
      this.userProjectList()
    },

    taskDateRange(nv) {
      const startOf = nv != 'Today' ? nv.split(' ')[1].toLowerCase() : 'day'
      this.start_date = moment().startOf(startOf).format('YYYY-MM-DD')
      this.end_date = moment().endOf(startOf).format('YYYY-MM-DD')
      this.userProjectList()
    },
  },
  mounted() {
    eventBus.$on('loadTaskListInDashboard', data => {
      if (data) {
        this.userProjectList()
      }
    })
  },
  methods: {
    /**
     * User Task Log Project Graphic List (ProgressBar)
     * @return userProjectLogList & totalProjectMinutes
     */
    async userProjectList() {
      this.loader = true
      const input = {
        start_date: this.start_date,
        end_date: this.end_date,
        user_id: this.userId,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/work-dashboard/user-project-log-list',
        input,
        false,
      )

      if (response && response.data) {
        const { data } = response
        this.userProjectLogList = data.user_project_log_lists
        this.totalProjectMinutes = data.total_project_minutes
        this.loader = false
      }
    },
  },
}
</script>
